/* Base
============================================================================= */
:root {
    --base-background-color: #fff;
    --base-color           : var(--mono-shade2);
    --base-font-family     : -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --base-font-size       : 16px;
    --base-font-weight     : normal;
    --base-letter-spacing  : ;
    --base-line-height     : 1.7;

    /* Emoji */
    --emoji-size           : calc(var(--base-line-height) * 1em);

    /* Horizontal Rule */
    --hr-border            : 1px solid var(--mono-tint2);

    /* Mark */
    --mark-background      : #ffecb3;
    --mark-color           : ;

    /* Preformatted */
    --pre-font-family      : var(--code-font-family);
    --pre-font-size        : var(--code-font-size);
    --pre-font-weight      : normal;
    --pre-line-height      : ;

    /* Selection */
    --selection-color      : #b4d5fe;

    /* Small */
    --small-font-size      : var(--font-size-s);

    /* Strong */
    --strong-color         : var(--heading-color);
    --strong-font-weight   : 600;

    /* Sub/Sup */
    --subsup-font-size     : var(--font-size-s);
}
