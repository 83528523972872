// Github Icon
// =============================================================================
.github-corner {
    position: absolute;
    z-index: map-get($z-index, githubcorner);
    top: 0;
    right: 0;
    border-bottom: 0;
    text-decoration: none;

    svg {
        height: 70px;
        width: 70px;
        fill: var(--theme-color);
        color: var(--base-background-color);
    }

    &:hover {
        .octo-arm {
            animation: octocat-wave 560ms ease-in-out;
        }
    }
}

@keyframes octocat-wave {
    0%, 100% {
        transform: rotate(0);
    }
    20%, 60% {
        transform: rotate(-25deg);
    }
    40%, 80% {
        transform: rotate(10deg);
    }
}


// Progress Bar
// =============================================================================
.progress {
    position: fixed;
    z-index: map-get($z-index, progress);
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    width: 0;
    background-color: var(--theme-color);
    transition: width var(--duration-fast), opacity calc(var(--duration-fast) * 2);
}


// Spinner
// =============================================================================
body.ready-transition {
    &:after,
    > *:not(.progress) {
        opacity: 0;
        transition: opacity var(--spinner-transition-duration);
    }

    // Spinner
    &:after {
        content: '';
        position: absolute;
        z-index: 1000;
        top: calc(50% - (var(--spinner-size) / 2));
        left: calc(50% - (var(--spinner-size) / 2));
        height: var(--spinner-size);
        width: var(--spinner-size);
        border: var(--spinner-track-width, 0) solid var(--spinner-track-color);
        border-left-color: var(--theme-color);
        border-left-color: var(--theme-color);
        border-radius: 50%;
        animation: spinner var(--duration-slow) infinite linear;
    }

    // Separate class required for spinner fade-in
    &.ready-spinner {
        &:after {
            opacity: 1;
        }
    }

    &.ready-fix {
        &:after {
            opacity: 0;
        }

        > *:not(.progress) {
            opacity: 1;
            transition-delay: var(--spinner-transition-duration);
        }
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
