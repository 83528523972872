// Content
// =============================================================================
:root {
    // Code
    --code-theme-background : #f3f3f3;
    --code-theme-comment    : #6e8090;
    --code-theme-function   : #dd4a68;
    --code-theme-keyword    : #07a;
    --code-theme-operator   : #a67f59;
    --code-theme-punctuation: #999;
    --code-theme-selector   : #690;
    --code-theme-tag        : #905;
    --code-theme-text       : #333;
    --code-theme-variable   : #e90;
}
